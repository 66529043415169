import React, {FC, useEffect, useState} from 'react';
import ForgotPassword from '../../components/forgot-password';
import SimpleLayout from '../../components/layout_simple';
import Login from '../../components/login';
import {arrowLeft} from '../../images/svgIcons';
import './styles.scss';
import { useQuery } from '@apollo/react-hooks';
import { ME } from '../../graphql/queries';
import { navigate } from 'gatsby';

const LoginPage: FC<any> = ({location}) => {
  const { state = {} } = location;
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const onBackBtnClick = () => {
    setShowForgotPassword(false);
  };
  const {data: userData} = useQuery(ME);

  useEffect(() => {
    if (userData && userData.User && userData.User.get && userData.User.get.id) {
      navigate('/');
    }
  }, [userData]);

  return (
    <SimpleLayout isClickable>
      {!showForgotPassword ? (
        <Login setShowForgotPassword={setShowForgotPassword} state={state}/>
      ) : (
        <div className="forgot-password-section">
          <span className="go-back" onClick={onBackBtnClick}>
            {arrowLeft}
          </span>
          <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
        </div>
      )}
    </SimpleLayout>
  );
};

export default LoginPage;
